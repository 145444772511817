var abp = abp || {};
(function () {
    /* DEFAULTS *************************************************/

    var defaultOptions = {
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        padding: 0,
        toast: true,
    };

    /* NOTIFICATION *********************************************/

    var showNotification = function (type, message, title, options) {
        var icon = options.imageClass ? '<i class="me-2 text-white ' + options.imageClass + '"></i>' : "";

        if (title) {
            options.title = icon + '<span class="text-white">' + title + "</span>";
        }

        options.html = (title ? "" : icon) + '<span class="text-white">' + message + "</span>";

        var combinedOptions = Object.assign({}, defaultOptions, options);
        const { imageClass, ...combinedOptionsSafe } = combinedOptions;

        /*
         * IMPORTANT:
         * The following line that initializes SweetAlert2 (`Swal.fire`) has been commented out:
         *
         * // Swal.fire(combinedOptionsSafe);
         *
         * By commenting out this line, the notification system (e.g., popups or toasts generated by SweetAlert2)
         * is effectively disabled. This ensures that no notification messages are displayed to the user.
         *
         * If this line is uncommented, SweetAlert2 will display notifications based on the options provided.
         * These notifications will re-enable messages such as success alerts, error toasts, or warnings
         * when triggered by various parts of the application.
         *
         * WARNING:
         * Uncommenting this line will cause SweetAlert2 notifications to appear across the application, which
         * may include internal server error messages, user feedback alerts, or other types of popups.
         * Use this feature cautiously to avoid showing unnecessary or disruptive notifications to the user.
         */
    };

    abp.notify.success = function (message, title, options) {
        showNotification(
            "success",
            message,
            title,
            Object.assign(
                {
                    background: "#34bfa3",
                    imageClass: "fa fa-check-circle",
                },
                options
            )
        );
    };

    abp.notify.info = function (message, title, options) {
        showNotification(
            "info",
            message,
            title,
            Object.assign(
                {
                    background: "#36a3f7",
                    imageClass: "fa fa-info-circle",
                },
                options
            )
        );
    };

    abp.notify.warn = function (message, title, options) {
        showNotification(
            "warning",
            message,
            title,
            Object.assign(
                {
                    background: "#ffb822",
                    imageClass: "fa fa-exclamation-triangle",
                },
                options
            )
        );
    };

    abp.notify.error = function (message, title, options) {
        showNotification(
            "error",
            message,
            title,
            Object.assign(
                {
                    background: "#f4516c",
                    imageClass: "fa fa-exclamation-circle",
                },
                options
            )
        );
    };
})();
